import { createMuiTheme } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        green: Palette['primary']
        disabled: {
            background: string
            color: string
        }
    }
    interface PaletteOptions {
        green: PaletteOptions['primary']
        disabled?: {
            background?: string
            color?: string
        }
    }
}

export const BaseTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#6180AB',
        },
        common: {
            white: '#fff',
        },
        grey: {
            100: '#4A4A4A',
        },
        green: {
            main: '#67969F',
            dark: '#76A2AA',
            light: '#BAD1D5',
        },
        disabled: {
            background: '#D6D6D6',
            color: '#00000061',
        },
    },
    typography: {},
})
